<template>
<div v-if="perms.includes('Produits & Stock') || perms.includes('Admin')">
     <div v-if="loading">
          <div id="loading-bg">
      <!-- <div class="loading-logo">
        <img src="<%= BASE_URL %>logo.png" alt="Logo" />
      </div> -->
      <div class="loading">
        <div class="effect-1 effects"></div>
        <div class="effect-2 effects"></div>
        <div class="effect-3 effects"></div>
      </div>
    </div>
  </div>
    <b-card v-else >
        <!-- input search -->
        <div class="custom-search">
        <b-row>
            <b-col md="3" >
            <b-form-group>
                <b-button
                variant="primary"
                v-b-toggle href="#example-sidebar" @click.prevent @click="showUpdate()"
            >
            <!-- v-b-toggle href="#addstock-sidebar" @click.prevent -->
                Nouveau Stock
            </b-button>
            </b-form-group>
            </b-col>
             <b-col md="3" ></b-col>
            <b-col md="6">
            <b-form-group>
            <div class="d-flex align-items-left">
            <!-- <label class="mr-1">chercher</label> -->
            <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block"
            />
            </div>
        </b-form-group>
            </b-col>
        </b-row>
        </div>
        <!-- <div class="custom-search d-flex justify-content-end">
            <b-button
                variant="primary"
                :to="{ name: 'facture-fournis-add'}"
            >
                Nouvelle Facture
            </b-button>
        <b-form-group>
            <div class="d-flex align-items-center">
            <label class="mr-1">Search</label>
            <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block"
            />
            </div>
        </b-form-group>
        </div> -->

        <!-- table -->
        <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        responsive
        :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
        :pagination-options="{
            enabled: true,
            perPage:pageLength
        }"
        >
        <template
            slot="table-row"
            slot-scope="props"
        >

            <!-- <div
            v-if="props.column.field === 'quantite'"
            class="text-nowrap"
            >
            <span > {{ props.row.NumFact }}</span>
            </div> -->
          


            <!-- Column: Action -->
            
            <!-- <span v-if="props.column.field === 'Add'" style="text-align: center;">
               <center>  <b-button
                        variant="gradient-success"
                        class="btn-icon"
                        v-b-toggle href="#example-sidebar" @click.prevent @click="showUpdate(props.row)"
                        >
                        <feather-icon icon="PlusIcon" />
                        
                    </b-button></center>
            </span>  -->
            

            <!-- Column: Common -->
        <!-- <span v-else>
            {{ props.formattedRow[props.column.field] }}
            </span> -->
        </template>

        <!-- pagination -->
        <template
            slot="pagination-bottom"
            slot-scope="props"
        >
            <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                Showing 1 to
                </span>
                <b-form-select
                v-model="pageLength"
                :options="['25','50','100']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap "> of {{ props.total }} entries </span>
            </div>
            <div>
                <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
                >
                <template #prev-text>
                    <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                    />
                </template>
                <template #next-text>
                    <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                    />
                </template>
                </b-pagination>
            </div>
            </div>
        </template>
        </vue-good-table>

    </b-card>
  
  <div>
<b-sidebar 
    id="example-sidebar"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right 
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Ajouter Nouveau Stock
        </h5>
<!-- {{countries}} -->
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- Body -->
      <b-form id="formdata"
        class="p-2"
        @submit.prevent
      >
            <!-- Update Fournisseur -->
        <b-form-group
          label="REF Produit"
          label-for="produitref"
        >
          <b-form-input
            id="produitref"
            v-model="form.ref_prod"
            trim
            disabled
          />
        </b-form-group>
        
        <!-- Product Name -->
        <b-form-group
          label="Nom Produit"
          label-for="produitname"
        >
          <b-form-input
            id="produitname"
            v-model="form.Nom"
            trim
            disabled
          />
        </b-form-group>

        
        <b-form-group
          label="Prix d'achat"
          label-for="Prix_Achat"
        >
          <b-form-input
            id="Prix_Achat"
            v-model="form.Prix_Achat"
            trim
            type="number"
            disabled
          />
        </b-form-group>

        <b-form-group
          label="Prix de vente"
          label-for="prixvente"
          
        >
          <b-form-input
            id="prixvente"
            v-model="form.Prix_Vente"
            trim
            type="number"
            disabled
          />
        </b-form-group>

         <b-form-group
          label="Qunatité"
          label-for="quantite"
        >
         <b-form-spinbutton
        id="demo-sb"
        v-model="form.quantite"
        min="1"
        max="1000000"
      />
          <!-- <b-form-input
            id="quantite"
            v-model="form.quantite"
            trim
            placeholder="40"
            type="number"
          /> -->
        </b-form-group>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
            @click="AddStock()"
          >
            Ajouter
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click="hide"
          >
            Annuler
          </b-button>
        </div>
      </b-form>
    </template>
</b-sidebar>

 
  </div>

     <!-- <sidebar-add-product /> -->
</div>
<div v-else>
  <not-authoriz></not-authoriz>
</div>
</template>

<script>
import config from '@/config'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard,BRow, BCol,BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, 
  BDropdownItem,BTooltip,BButton,VBToggle,BCollapse,BSidebar,BForm,BFormSpinbutton
} from 'bootstrap-vue'
import router from '@/router'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
 import SidebarAddProduct from './SidebarAddProduct.vue'
import vSelect from 'vue-select'
import NotAuthoriz from '../NotAuthoriz.vue'


export default {
      directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
      BCard,BCollapse,BFormSpinbutton,
    BCardCode,BRow, BCol,
    VueGoodTable,
    BAvatar,vSelect,BSidebar,BForm,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BTooltip,
    BButton,
    SidebarAddProduct,NotAuthoriz
  },
  data() {
    return {
      perms:[],
        loading:false,
         fournis:[],
          products:[],
           fourniID:{},
           ProductID:{},
           UserID:{},
          form :new Form({    
          id:0,
          Nom:'',
          ref_prod:'',
          Prix_Achat:'',
          Prix_Vente:'',
          quantite:'',
          }),
          prodId:0,
          produit:{},
          
          showSide:true,
      pageLength: 10,
      dir: false,
      columns: [
           {
          label: 'R-E-F',
          field: 'ref_prod',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher.. ',
          },
        },
        {
          label: 'Produit',
          field: 'Nom',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher ',
          },
        },
         {
          label: 'Quantité Stock',
          field: 'qnt',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher ...',
          },
        },
        {
          label: 'Note',
          field: 'note',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher ...',
          },
        },
        {
          label: 'Date',
          field: 'created_at',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher ..',
          },
        },
        
        
        // {
        //   label: 'New',
        //   field: 'Add',
        // },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        cheque : 'light-success',
        ESPECE     : 'light-warning',
        CHEQUE : 'light-success',
        espece     : 'light-warning',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
        this.perms=JSON.parse(localStorage.getItem('access'));
        this.getProduct()
        this.$http.get(`${config.API_BASE_URL}/api/auth/ShowProduct/${router.currentRoute.params.id}`).then(res => { this.produit = res.data })
        this.$http.get(`${config.API_BASE_URL}/api/auth/getProducts`).then(res => { this.products = res.data })
        this.prodId=router.currentRoute.params.id
        this.UserID=JSON.parse(localStorage.getItem('userData'))
  },
  methods:{
    getProduct(){
      this.loading = true
        this.$http.get(`${config.API_BASE_URL}/api/auth/getStockProduct/${router.currentRoute.params.id}`).then(res => { this.rows = res.data,this.loading = false })
      },
    showUpdate(){
        this.form.reset();
        this.form.fill(this.produit);
        },
   
    AddStock(){
        let data= new FormData;
          data.append('quantite', this.form.quantite);
          data.append('UserID', this.UserID.id);
        this.$http.post(`${config.API_BASE_URL}/api/auth/EditStock/${router.currentRoute.params.id}`,data) .then(res => {   
            if(res.data=='Success'){
                this.getProduct()
                this.$http.get(`${config.API_BASE_URL}/api/auth/ShowProduct/${router.currentRoute.params.id}`).then(res => { this.produit = res.data })
                this.$swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Stock  est bien modifier',
                showConfirmButton: false,
                timer: 1000 })
                }      
        })
        .catch(() => {
            // console.log("Error......")
            this.$swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong! Pleaz try again'
                })
        })  
        },
    DeleteProduct(id){
                   this.$swal.fire({
                        text: "Vous voulez supprimer ce produit ?",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Oui, supprimer!'
                      }).then((result) => {
                        if (result.value) {
                            this.$http.post(`${config.API_BASE_URL}/api/auth/Deleteproduct/${id}`)
                              .then(res=> {
                                    if(res.data=='Success'){
                                    this.$swal.fire({
                                    position: 'top-end',
                                    icon: 'success',
                                    title: 'Produit est bien supprimé',
                                    showConfirmButton: false,
                                    timer: 1500  })   
                                    this.getProduct(); 
                                  }else if(res.data=='error'){
                                      this.$swal.fire({
                                        icon: 'error',
                                        title: 'Oops...',
                                        text: 'Vous pouvez pas supprimer ce produit, Déjà affecter à une facture. '
                                      });
                                  }    
                              }).catch(() => {
                                  this.$swal.fire({
                                        icon: 'error',
                                        title: 'Oops...',
                                        text: 'Something went wrong! Pleaz try again'
                                      });
                              })
                          }             
                      }) 
    }
  }
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';

</style>
